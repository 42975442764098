.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); // Adjust the opacity as needed
  z-index: 999998; // Set below `.container` but above other content
}
.route {
  border: 1.5px solid white;
}
.container {
  background-color: #000000;
  color: #ffffff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999;
  width: 90%;
  max-width: 480px;
  // Instead of a fixed height, use min-height or auto to let content determine height.
  min-height: 60vh;
  padding: 16px;
  gap: 24px;
  border-radius: 24px;
  font-weight: 600;
  
  input {
    font-family: "Inter", sans-serif !important;
        // Autofill Fixes
        &:-webkit-autofill {
          -webkit-text-fill-color: black !important; // Force white text color
          box-shadow: 0 0 0 100px transparent inset !important; // Neutralize autofill background
          background-color: transparent !important; // Ensure no background
          transition: background-color 5000s ease-in-out 0s; // Smooth transition fix
        }
    
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          -webkit-text-fill-color: black !important;
          box-shadow: 0 0 0 100px transparent inset !important;
          background-color: transparent !important;
        }
  }
  button {
    font-family: "Inter", sans-serif !important;
  }
  input:-webkit-autofill {
    color: #000 !important;
  }
  .parent {
    display: grid;
    grid-template-columns: 1fr 0.15fr 1fr;
    grid-template-rows: repeat(7, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 20px;
  }

  .div1 {
    grid-area: 1 / 1 / 2 / 4;
    p {
      font-size: 28px;
      font-weight: 800;
      line-height: 28px;
      text-align: center;
    }
  }
  .div2 {
    grid-area: 2 / 1 / 3 / 2;
    input {
      border-radius: 10px;
      padding-left: 5px;
      border: none;
      background-color: #ffffff;
      color: #000000 !important;
      width: 100%;
      height: 100%;
    }
  }
  .div3 {
    grid-area: 2 / 3 / 3 / 4;
    input {
      border-radius: 10px;
      padding-left: 5px;
      border: none;
      background-color: #ffffff;
      color: #000000 !important;
      width: 100%;
      height: 100%;
    }
  }
  .div4 {
    grid-area: 3 / 1 / 4 / 4;
    gap: 8px;
    border-radius: 8px;
    border: 1.2px;
    width: 100%;
    margin-top: 0px;
    background-color: white !important;
    color: #000000 !important;
    input {
      width: 100%;

      padding: 8px 16px 8px 16px;
      gap: 8px;
      border-radius: 8px;
      border: 1.2px 0px 0px 0px;
      // opacity: 0px;
      border: 1.2px solid #ffffff33;
      background-color: white !important;
      color: #000000 !important;
      line-height: normal;
    }
    input:focus {
      outline: none;
    }
  }
  .div5 {
    grid-area: 4 / 1 / 5 / 2;
    position: relative;
    input {
      border-radius: 10px;
      padding-left: 5px;
      border: none;
      background-color: #ffffff;
      color: #000000 !important;
      width: 100%;
      height: 100%;
    }
    .hide {
      display: none;
    }
    .labelDate {
      display: none;
      position: absolute;
      left: 5px;
      top: 5px;
      font-size: 16px;
      color: #000000;
    }
  }
  .div6 {
    grid-area: 4 / 3 / 5 / 4;
    select {
      width: 100%;
      height: auto;
      font-size: 16px;
      border-radius: 10px;
      padding: 9.25px 0;
      margin: 0px 0;
      background-color: #ffffff !important;
      color: #000 !important;
      height: 100%;
    }
  }

  .div9 {
    grid-area: 5 / 1 / 5 / 4;
    select {
      width: 100%;
      height: auto;
      font-size: 16px;
      border-radius: 10px;
      padding: 9.25px 0;
      margin: 0px 0;
      background-color: #ffffff !important;
      color: #000 !important;
      height: 100%;
    }
  }
  .div7 {
    grid-area: 6 / 1 / 6 / 4;
    display: flex;
    align-items: flex-start;
    gap: 5%;
    input[type="checkbox"] {
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      width: 24px; /* Adjust size */
      height: 24px; /* Adjust size */
      background-color: white;
      border: none;
      border-radius: 4px; /* Rounded corners if needed */
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center; /* Centers checkmark */
      position: relative;
    }

    /* Style the check mark */
    input[type="checkbox"]:checked::before {
      content: "✓"; /* Unicode for check mark */
      color: black !important;
      font-weight: bold;
      font-size: 18px; /* Adjust size of check mark */
      display: flex;
      align-items: center;
      justify-content: center;
    }

    p {
      font-size: 15px;
      font-weight: 400;
      line-height: 19.36px;
      text-align: left;
      span {
        text-decoration: underline;
        line-height: 19.36px !important;
        cursor: pointer;
      }
    }
  }
  .div8 {
    grid-area: 7 / 1 / 9 / 4;

    .submit {
      width: 100%;
      button {
        padding: 12px 8px 12px 8px;
        gap: 8px;
        border-radius: 20px;
        background: #ffffff;
        color: #000000;
        width: 100%;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        text-align: center;

        line-height: normal;
        transition: transform 300ms ease-out;
        cursor: pointer;
        &:disabled {
          background: #ffffff4d;
          cursor: not-allowed;
        }
        &:active {
          transform: scale(0.95); // Scale down slightly on click
        }
      }
    }
  }

}

.submit {
  width: 100%;
  button {
    padding: 12px 8px 12px 8px;
    gap: 8px;
    border-radius: 20px;
    background: #ffffff;
    color: #000000;
    width: 100%;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;

    line-height: normal;
    transition: transform 300ms ease-out;
    cursor: pointer;
    &:disabled {
      background: #ffffff4d;
      cursor: not-allowed;
    }
    &:active {
      transform: scale(0.95); // Scale down slightly on click
    }
  }
}

.div4 {
  display: flex;
  align-items: center;
}

.flagAndCode {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.phoneInput {
  flex-grow: 1;
}

.phone_input {
  width: 100%;
}
.div5 input[type="date"],
.div6 select {
  font-size: 16px;
}
.div5 input[type="date"]:focus {
  outline: none;
}
@media screen and (max-width: 600px) {
  .container {
    .parent {
      .div5 input[type="date"] {
  font-size: 20px !important;
}
      .div1 {
        p {
          font-size: 17px;
        }
      }
      .div5 {
        input {
          // width: -webkit-fill-available !important;
        }
        .labelDate {
          display: block;
        }
      }
      .div7 {
        grid-area: 6 / 1 / 6 / 4;
        display: flex;
        align-items: center;
        gap: 5%;
        input[type="checkbox"] {
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          width: 35px; /* Adjust size */
          height: 25px; /* Adjust size */
          background-color: white;
          border: none;
          border-radius: 4px; /* Rounded corners if needed */
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center; /* Centers checkmark */
          position: relative;
          margin-top: 5px;
        }

        /* Style the check mark */
        input[type="checkbox"]:checked::before {
          content: "✓"; /* Unicode for check mark */
          color: black !important;
          font-weight: bold;
          font-size: 18px; /* Adjust size of check mark */
          display: flex;
          align-items: center;
          justify-content: center;
        }

        p {
          font-size: 10px;
          font-weight: 400;
          line-height: 19.36px;
          text-align: left;
          span {
            text-decoration: underline;
            line-height: 19.36px !important;
          }
        }
      }
    }
  }
}
